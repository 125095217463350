import React, { useState } from "react";
import { motion } from "framer-motion";
import { screens } from "./data";
import { Box } from "@mui/material";

export default function NavItems() {
  const [hovered, setHovered] = useState<number | null>(null);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "40px",
        justifyContent: "center",
        position: "relative",
        zIndex: 10,
      }}
    >
      {screens.map(({ title, isLink, link, inPage }, i) => {
        return !isLink ? (
          <Box
            key={i}
            component={motion.li}
            onHoverStart={() => setHovered(i)}
            onHoverEnd={() => setHovered(null)}
            sx={{
              cursor: "pointer",
              fontSize: "20px",
              fontFamily: "inter",
              fontWeight: 500,
              position: "relative",
              zIndex: 10,

              "&:after": {
                // CSS for the underline
                content: '""',
                position: "absolute",
                bottom: "-8px",
                left: 0,
                right: 0,
                zIndex: 10,

                height: "2px",
                backgroundColor: screens[i].color,
                width: hovered === i ? "100%" : "0%", // Full width if selected or hovered, else no width
                transition: "width 0.3s ease-in-out", // Smooth transition for the width
              },
            }}
          >
            {title}
          </Box>
        ) : (
          <a
            href={link}
            target={inPage ? "_self" : "_blank"}
            style={{
              textDecoration: "none",
              color: "#030303",
            }}
          >
            <Box
              key={i}
              component={motion.li}
              onHoverStart={() => setHovered(i)}
              onHoverEnd={() => setHovered(null)}
              sx={{
                cursor: "pointer",
                fontSize: "20px",
                fontFamily: "inter",
                fontWeight: 500,
                position: "relative",
                zIndex: 10,
                "&:after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-8px",
                  left: 0,
                  right: 0,
                  zIndex: 10,

                  height: "2px",
                  backgroundColor: screens[i].color,
                  width: hovered === i ? "100%" : "0%", // Full width if selected or hovered, else no width
                  transition: "width 0.3s ease-in-out", // Smooth transition for the width
                },
              }}
            >
              {title}
            </Box>
          </a>
        );
      })}
    </Box>
  );
}
