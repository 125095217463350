import { Box, useMediaQuery, useTheme } from "@mui/material";
import NavBar from "../../Components/NavBar";
import Landing from "../../Sections/Landing";
import Work from "../../Sections/Work";
import "../../../src/index.css";
import { useCycle } from "framer-motion";
const blob = require("../../assets/magicpattern-blob-1710787651582.png");
const blob2 = require("../../assets/magicpattern-blob-1710781780794.png");
const blob3 = require("../../assets/magicpattern-blob-1710781848351.png");

const Home = () => {
  const theme = useTheme();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(4000));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          background: "linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
          height: "100%",
          maxWidth: "2000px",
          position: "relative",
          width: "100%",
        }}
      >
        <NavBar onToggle={toggleOpen} />
        <Landing />

        <Box
          component="img"
          src={blob2}
          alt="blobImage"
          sx={{
            height: "500px",
            zIndex: 1,
            width: "500px",
            position: "absolute",
            animation: "floatSideways 8s ease-in-out infinite",
            filter: "blur(5px)",
            left: "-15%",
            bottom: "20%",
            [theme.breakpoints.up(2000)]: {
              left: "0%",
              bottom: "0%",
            },
            [theme.breakpoints.down(1000)]: {
              left: "-25%",
              bottom: "25%",
            },
            [theme.breakpoints.down(900)]: {
              left: "-35%",
              bottom: "30%",
            },
            [theme.breakpoints.down(800)]: {
              left: "-40%",
              bottom: "35%",
            },
            [theme.breakpoints.down(700)]: {
              left: "-45%",
              bottom: "40%",
            },
            [theme.breakpoints.down(500)]: {
              left: "-65%",
              bottom: "40%",
            },
          }}
        />
        <Box
          component="img"
          src={blob3}
          alt="blobImage"
          sx={{
            height: "500px",
            zIndex: 1,
            width: "700px",
            position: "absolute",
            filter: "blur(5px)",
            right: "-20%",
            animation: "float 7s ease-in-out infinite",
            top: "-20%",
            [theme.breakpoints.up(2000)]: {
              right: "5%",
              bottom: "20%",
            },
            [theme.breakpoints.down(1000)]: {
              right: "-25%",
              top: "-25%",
            },
            [theme.breakpoints.down(900)]: {
              right: "-30%",
              top: "-30%",
            },
            [theme.breakpoints.down(800)]: {
              right: "-35%",
              top: "-35%",
            },
            [theme.breakpoints.down(700)]: {
              right: "-50%",
              top: "-30%",
            },
            [theme.breakpoints.down(600)]: {
              right: "-80%",
              top: "-30%",
            },
          }}
        />
      </Box>
      <Box
        component="img"
        src={blob}
        alt="blobImage"
        sx={{
          height: "500px",
          width: "500px",
          position: "absolute",
          zIndex: 1,
          filter: "blur(5px)",
          right: "0%",
          bottom: "-25%",
          [theme.breakpoints.up(2000)]: {
            right: "10%",
            bottom: "22%",
          },
          [theme.breakpoints.up(3000)]: {
            right: "30%",
            bottom: "50%",
          },
          [theme.breakpoints.down(1000)]: {
            right: "0%",
            bottom: "-25%",
          },
          [theme.breakpoints.down(900)]: {
            right: "0%",
            bottom: "-25%",
          },
          [theme.breakpoints.down(800)]: {
            right: "0%",
            bottom: "-30%",
          },
          [theme.breakpoints.down(700)]: {
            right: "0%",
            bottom: "-35%",
            height: "300px",
            width: "300px",
          },
        }}
      />
      {!isOpen && <Work onToggle={toggleOpen} />}
    </Box>
  );
};

export default Home;
