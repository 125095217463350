import { Box, Typography, useTheme } from "@mui/material";
import SidebarNav from "../SidebarNav";
import NavItems from "../NavItems/NavItems";
import { motion, useCycle } from "framer-motion";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

const NavBar = ({ onToggle }: any) => {
  const theme = useTheme();
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <>
      <motion.div
        initial={{ y: -100 }} // Initial position before animation
        animate={{ y: 0 }} // Y position animation when sticky
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        style={{
          width: "100%",
          display: "flex",
          height: "5vh",
          maxWidth: "1800px",
          backgroundColor: "red",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          background: "transparent",
          paddingTop: "30px",
          zIndex: 10,
          [theme.breakpoints.down(750)]: {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "70px",
            top: "25px",
            [theme.breakpoints.down(750)]: {
              width: "100%",
              position: "unset",
              justifyContent: "flex-end",
              paddingRight: 5,
              paddingBottom: 2,
              alignItems: "center",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "inter",
              fontWeight: 500,
            }}
          >
            louis cappell
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
            gap: "20px",
            [theme.breakpoints.down(750)]: {
              display: "none",
            },
          }}
        >
          <NavItems />
        </Box>
      </motion.div>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          zIndex: 100,
          display: "none",
          [theme.breakpoints.down(750)]: {
            display: "block",
          },
        }}
      >
        <SidebarNav
          isOpen={isOpen}
          toggleOpen={() => {
            onToggle();
            toggleOpen();
          }}
        />
        {isOpen && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: "100px",
              position: "absolute",
              bottom: 250,
              zIndex: 10000,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 1 }}
            >
              <a
                href="https://www.linkedin.com/in/louiscappell?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BVMMUvaBgRg2cKz07Yg5vcg%3D%3D"
                style={{ textDecoration: "none", color: "#030303", zIndex: 2 }}
                target="_blank"
              >
                <LinkedInIcon sx={{ fontSize: "45px" }} />
              </a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 1 }}
            >
              <a
                href="https://github.com/LCappell"
                target="_blank"
                style={{ textDecoration: "none", color: "#030303", zIndex: 2 }}
              >
                <GitHubIcon sx={{ fontSize: "45px" }} />
              </a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 1 }}
            >
              <a
                href="mailto:louis.cappell@icloud.com"
                target="_blank"
                style={{ textDecoration: "none", color: "#030303", zIndex: 2 }}
              >
                <EmailIcon sx={{ fontSize: "45px" }} />
              </a>
            </motion.div>
          </Box>
        )}
      </Box>
    </>
  );
};

export default NavBar;
