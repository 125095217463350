import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";

export const Navigation = ({
  isOpen,
  toggle,
}: {
  isOpen: boolean;
  toggle: any;
}) => (
  <motion.ul
    style={{
      display: isOpen ? "flex" : "none",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "15px",
      // position: "relative",
    }}
  >
    <Box
      onClick={toggle}
      component={motion.div}
      style={{
        zIndex: 3,
        width: "100%",
      }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "30px",
        padding: "20px",
      }}
    >
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <a
            href="https://linktr.ee/louiscappell"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#030303",
            }}
          >
            <Typography
              sx={{
                zIndex: 100,
                fontFamily: "inter",
                fontSize: "26px",
                fontWeight: 600,
              }}
            >
              contact me.
            </Typography>
          </a>
        </motion.div>
      )}
    </Box>
    <Box
      component={motion.div}
      onClick={toggle}
      style={{
        zIndex: 3,
        width: "100%",
      }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "30px",
        padding: "20px",
      }}
    >
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <a
            href="#work"
            style={{
              textDecoration: "none",
              color: "#030303",
            }}
          >
            <Typography
              sx={{
                zIndex: 100,
                fontFamily: "inter",
                fontSize: "26px",
                fontWeight: 600,
              }}
            >
              my work.
            </Typography>
          </a>
        </motion.div>
      )}
    </Box>
  </motion.ul>
);
