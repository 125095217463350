import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";

const WorkItem = ({
  title,
  images,
  imageDim,
  summary,
  role,
  date,
  techStack,
  longSummary,
  links,
  onModalOpen,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const variants = {
    open: {
      scale: 1.05,
      opacity: 1,
      transition: { type: "spring", stiffness: 100, damping: 10 },
    },
    closed: {
      scale: 0.9,
      opacity: 1,
      transition: { type: "spring", stiffness: 100, damping: 10 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1000));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down(800));
  const imagesArr = !isOpen
    ? images.slice(0, 2)
    : isSmallMobile
    ? images
    : isMobile
    ? images.slice(0, 1)
    : images;

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (rect.top < windowHeight && rect.bottom > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check visibility on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      ref={ref}
      component={motion.div}
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      sx={{
        display: "flex",
        alignItems: isOpen ? "flex-start" : "center",
        justifyContent: "flex-start",
        width: isSmallMobile && !isOpen ? "80%" : "40%",
        position: isOpen ? "absolute" : "relative",
        backdropFilter: "blur(20px)",
        flexDirection: "column",
        background: "rgba(255, 255, 255, 0.3)",
        borderRadius: "16px",
        padding: "5px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)",
        zIndex: isOpen ? 11 : 10,
        border: "1px solid rgba(255, 255, 255, 0.17)",
        "&:hover": {
          cursor: !isOpen ? "pointer" : "auto",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
          transform: "scale(1.1)",
        },
      }}
      onClick={() => onModalOpen({ title, longSummary, links, techStack })}
    >
      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontFamily: "inter",
            fontWeight: 600,
            opacity: 0.75,
            paddingBottom: 1,
          }}
        >
          {title}
        </Typography>
        {isOpen && (
          <CloseIcon
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 20,
              top: 10,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {(!isSmallMobile || !isOpen) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: "10px",
                display: "flex",
                alignItems: isOpen ? "flex-start" : "center",
                justifyContent: isOpen ? "flex-start" : "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <>
                {imagesArr.map((img: any) => (
                  <Box
                    component="img"
                    src={img}
                    alt="example work"
                    style={{
                      width: "100%",
                      height: imageDim.height,
                      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      paddingBottom: 1,
                    }}
                  />
                ))}
              </>
            </Box>

            <Box
              sx={{
                marginTop: "10px",
                paddingX: isOpen ? "20px" : 0,
                display: "flex",
                alignItems: isOpen ? "flex-start" : "center",
                justifyContent: isOpen ? "flex-start" : "center",
                flexDirection: "column",
              }}
            >
              {!isOpen && (
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "inter",
                    fontWeight: 500,
                    opacity: 0.7,
                  }}
                >
                  {summary}
                </Typography>
              )}
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "inter",
                  fontWeight: 500,
                  opacity: 0.7,
                  mt: "5px",
                }}
              >
                {role}
              </Typography>
              <Typography
                sx={{
                  fontSize: "17px",
                  fontFamily: "inter",
                  fontWeight: 500,
                  opacity: 0.7,
                  mt: "5px",
                }}
              >
                {date}
              </Typography>
            </Box>
          </Box>
        )}
        {isOpen && (
          <Box
            sx={{
              width: "50%",
              height: "100",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "inter",
                }}
              >
                Details
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: "inter",
                opacity: 0.8,
              }}
            >
              {isOpen ? longSummary : ""}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WorkItem;
