export const screens = [
  {
    title: "my work.",
    isLink: true,
    link: "#work",
    color: "#0099ff",
    inPage: true,
  },
  {
    title: "contact me.",
    isLink: true,
    link: "https://linktr.ee/louiscappell",
    color: "#22cc88",
  },
];
