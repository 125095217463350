import React from "react";
import Home from "./Pages/Home";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/300.css";
import "@fontsource/archivo-black";
import "@fontsource/inter";

const App = () => {
  return <Home />;
};

export default App;
