import "./styles.css";

import { motion } from "framer-motion";
import { MenuToggle } from "./Hamburger";
import { Navigation } from "./Items";
import { Box } from "@mui/material";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
  closed: {
    clipPath: "circle(20px at 40px 40px)",
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const SidebarNav = ({ isOpen, toggleOpen }: any) => {
  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom="100%"
      style={{
        zIndex: 1000,
      }}
    >
      {isOpen ? (
        <motion.div
          className="navbar"
          variants={sidebar}
          style={{
            zIndex: 3,
          }}
        />
      ) : (
        <motion.div
          variants={sidebar}
          style={{
            zIndex: 3,
          }}
        />
      )}
      <Navigation isOpen={isOpen} toggle={() => toggleOpen()} />
      <MenuToggle isOpen={isOpen} toggle={() => toggleOpen()} />
    </motion.nav>
  );
};

export default SidebarNav;
